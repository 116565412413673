<template>
        <v-responsive column>
                <v-data-table
                        dense
                        :headers="headers"
                        :items="data"
                        :search="search"
                        :class="{mobile: isMobile}"
                        :sort-by="['LastUpdate']"
                        :sort-desc="[true]"
                        :itemsPerPage="50"
                >
                <template slot="item" slot-scope="props">
                        <tr v-if="!isMobile">
                                <td>#{{ props.item.P_Id }}</td>
                                <td class="text-xs-left" style="font-weight: 700">{{ props.item.P_Header }}</td>
                                <td class="text-xs-left">{{ props.item.P_Host }}</td>
                                <td class="text-xs-left">{{ props.item.P_Port }}</td>
                                <td class="text-xs-left">{{ props.item.P_Username }}</td>
                                <td class="text-xs-left">{{ props.item.P_Password }}</td>
                                <td class="text-xs-left">{{ props.item.P_DB_Brand }}</td>
                                <td class="text-xs-left">{{ props.item.PlacePeoples }}</td>

                                <td class="text-xs-left" v-if="props.item.ConnectionError == 0">
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="green" dark v-bind="attrs" v-on="on" :loading="dialog">
                                                                done
                                                        </v-icon>
                                                </template>
                                                <span>Bağlantı sağlandı..</span>
                                        </v-tooltip>
                                </td>

                                <td class="text-xs-left" v-else>
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="red" dark v-bind="attrs" v-on="on" :loading="dialog">
                                                                signal_wifi_statusbar_connected_no_internet_4
                                                        </v-icon>
                                                </template>
                                                <span>{{ props.item.ErrorMessage }}</span>
                                        </v-tooltip>
                                </td>
                                <td class="text-xs-left">
                                        <time-ago tooltip locale="tr" :refresh="10" :datetime="props.item.UpdateTime"></time-ago>
                                </td>
                                <td class="text-xs-left" v-if="props.item.LastUpdate"><time-ago tooltip locale="tr" :refresh="10" :datetime="props.item.LastUpdate"></time-ago>
                                        <v-tooltip bottom v-if="props.item.Note">
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" :disabled="dialog" :loading="dialog" @click="PlaceNote(props.item.Note)">
                                                                sms
                                                        </v-icon>
                                                </template>
                                                <span>{{ props.item.Note }}</span>
                                        </v-tooltip>
                                </td>
                                <td class="text-xs-left" style="color:red;" v-else>İşlem Yok
                                        <v-tooltip bottom v-if="props.item.Note">
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" :disabled="dialog" :loading="dialog" @click="PlaceNote(props.item.Note)">
                                                                sms
                                                        </v-icon>
                                                </template>
                                                <span>{{ props.item.Note }}</span>
                                        </v-tooltip>
                                </td>



                                <td class="text-xs-left">

                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" :disabled="InHouseSoftware(props.item.P_DB_Brand,true)" :loading="dialog" @click="PlaceUpdate(props.item)">
                                                                autorenew
                                                        </v-icon>
                                                </template>
                                                <span>Kullanıcıları güncelle</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" :disabled="InHouseSoftware(props.item.P_DB_Brand,props.item.LastUpdate)" :loading="dialog" @click="CurrentPeoples(props.item)">
                                                                reorder
                                                        </v-icon>
                                                </template>
                                                <span>İçerdekiler</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" :disabled="props.item.PlacePeoples<1" :loading="dialog" @click="RegisterUser(props.item)">
                                                                how_to_reg
                                                        </v-icon>
                                                </template>
                                                <span>Toplam kayıtlı kullanıcı</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" :disabled="dialog" :loading="dialog" @click="UpdateOpenConfig(props.item)">
                                                                create
                                                        </v-icon>
                                                </template>
                                                <span>Düzenle</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="red" dark v-bind="attrs" v-on="on" :disabled="dialog" :loading="dialog" @click="OpenDeleteDialog(props.item)">
                                                                delete
                                                        </v-icon>
                                                </template>
                                                <span>Sil</span>
                                        </v-tooltip>
                                </td>
                        </tr>
                        <tr v-else>
                                <td>
                                        <ul class="flex-content">
                                                <li class="flex-item" data-label="Id">#{{ props.item.P_Id }}</li>
                                                <li class="flex-item" data-label="Tesis">{{ props.item.P_Header }}</li>
                                                <li class="flex-item" data-label="İp">{{ props.item.P_Host }}</li>
                                                <li class="flex-item" data-label="Port">{{ props.item.P_Port }}</li>
                                                <li class="flex-item" data-label="User">{{ props.item.P_Username }}</li>
                                                <li class="flex-item" data-label="Pass">{{ props.item.P_Password }}</li>
                                                <li class="flex-item" data-label="Data">{{ props.item.P_DB_Brand }}</li>
                                                <li class="flex-item" data-label="Kişi">{{ props.item.PlacePeoples }}</li>
                                                <li class="flex-item" data-label="Durum" style="color:red;">{{ props.item.ConnectionError }}</li>
                                                <li class="flex-item" data-label="Son bağlantı">{{ props.item.UpdateTime }}</li>
                                                <li class="flex-item" data-label="Son işlem" v-if="props.item.LastUpdate"><timeago :datetime="props.item.LastUpdate" :auto-update="20"></timeago></li>
                                                <li class="flex-item" data-label="Son işlem" style="color:red;" v-else>İşlem Yok</li>
                                                <li class="flex-item" data-label="İşlem"> <v-icon style="font-size:20px;color:green"
                                                                                                  :disabled="dialog"
                                                                                                  :loading="dialog"
                                                                                                  @click="PlaceUpdate(props.item)">autorenew</v-icon></li>
                                        </ul>
                                </td>
                        </tr>
                </template>
                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                        Arama : "{{ search }}" Değeri bulunamadı.
                </v-alert>
                </v-data-table>
        </v-responsive>
</template>

<script>
import { TimeAgo } from 'vue2-timeago'

export default {
name: "HotSpotList",
        components: {
                TimeAgo,
        },
data(){
        return {
                loading:true,
                dialog:false,
                isMobile: false,
                headers: [
                        {
                                text: '#Id',
                                align: 'left',
                                value: 'P_Id'
                        },
                        {
                                text: 'Tesis',
                                value: 'P_Header'
                        },
                        {
                                text: 'Host',
                                value: 'P_Host'
                        },
                        {
                                text: 'Port',
                                value: 'P_Port'
                        },
                        {
                                text: 'Username',
                                value: 'P_Username'
                        },
                        {
                                text: 'Şifre',
                                value: 'P_Password'
                        },
                        {
                                text: 'Sağlayıcı',
                                value: 'P_DB_Brand'
                        },
                        {
                                text: 'Kişi',
                                value: 'PlacePeoples'
                        },
                        {
                                text: 'Durum',
                                value: 'ConnectionError'
                        },
                        {
                                text: 'Son Bağlantı',
                                value: 'UpdateTime'
                        },
                        {
                                text: 'Son işlem',
                                value: 'LastUpdate'
                        },
                        {
                                text: 'İşlem',
                                value: 'PlaceAction'
                        },
                ],
                Log: {
                        Search:null,
                        status:false,
                        Pagination: {
                                rowsPerPage: 15,
                                sortBy: 'created',
                                descending: true,
                        },
                        Data:[],
                        Header:[
                                {
                                        text: 'Tarih',
                                        align: 'left',
                                        value: 'created'
                                },{
                                        text: 'İşlem Tipi',
                                        align: 'left',
                                        value: 'Type'
                                },{
                                        text: 'Kullanıcı',
                                        align: 'left',
                                        value: 'UserFullname'
                                },{
                                        text: 'İp Addresi',
                                        align: 'left',
                                        value: 'IpAddress'
                                },{
                                        text: 'Tarayıcı',
                                        align: 'left',
                                        value: 'UserAgent'
                                }
                        ],
                        Detail:false,
                        DetailData:[]
                },
                note:'',
                noteShow:false,
        }
},
        methods: {
                PlaceDataList() {
                        if(this.data.length<1){
                                this.$store.dispatch("HotSpotListUpdate")
                        }
                },
                time_ago(ms) {
                        return ms;
                },
                CurrentPeoples(row){
                        this.$store.dispatch("inhouse/GetData",row)
                },
                RegisterUser(row){
                        this.$store.dispatch("registeredUsers/GetData",row)
                },
                InHouseSoftware(program,kisi){
                        if(program=='MooWifi' || program=='API BAGLANTISI' || program=='API BAGLANTISI' || program=='' || program=='API MooWifi'){
                                return true
                        }else if(kisi==null) {
                                return true
                        }else{
                                return false
                        }
                },
                PlaceUpdate(row){
                        this.$store.dispatch("refreshplace/GetData",row)
                }
        },
        computed:{
                data:{
                        get(){
                                return this.$store.state.hotspot.data
                        },
                        set(val){
                                this.$store.hotspot.commit("setHotSpotList",val)
                        }
                },
                search(){
                        return this.$store.state.search;
                }
        },
        mounted(){
                if(this.$store.state.isLogin){
                        this.$store.state.PageTitle = "HotSpot Listesi"
                        this.PlaceDataList();
                }
        },
    }
</script>

<style scoped>

</style>